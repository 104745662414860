import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.EditCamera,
    onClose: _ctx.close,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        "label-position": _ctx.$formLabelPosition,
        model: _ctx.formData,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.DeviceDetailDetailUnitName
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formData.UnitName), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.RDeviceSearchLabelRoomName
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formData.RoomName), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPersonDeviceInHtmlLocation,
            prop: "Location"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Location,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Location = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label-position", "model", "rules"])
    ]),
    _: 1
  }, 8, ["title", "onClose", "onSubmit"]))
}