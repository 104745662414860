
import {
    defineComponent, PropType, reactive, ref
} from 'vue';
import DialogShell from '@/components/common/dialog-shell/index.vue';
import { deviceForPMApi } from '@/api';

interface CameraInfo{
    UnitName: string;
    RoomName: string;
    Location: string;
    UUID: string;
}

export default defineComponent({
    components: { DialogShell },
    props: {
        cameraInfo: {
            type: Object as PropType<CameraInfo>,
            default: {}
        }
    },
    emits: ['success', 'close'],
    setup(props, { emit }) {
        const formRef = ref();
        const formData = reactive({ ...props.cameraInfo });

        const rules = {
            Location: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPersonDeviceInHtmlLocation), trigger: 'blur' }
            ]
        };

        function close() {
            emit('close');
        }

        function submit() {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    deviceForPMApi.editThirdPartCamera({
                        UUID: formData.UUID,
                        Location: formData.Location
                    }, () => {
                        emit('success');
                        close();
                    });
                }
            });
        }
        return {
            close,
            submit,
            rules,
            formRef,
            formData
        };
    }
});
