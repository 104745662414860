
import {
    defineComponent, reactive, ref, watch
} from 'vue';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import List from '@/components/common/list/src/list.vue';
import CameraEditDialog from '@/views/pm/device/components/camera-edit-dialog.vue';
import { overlayFormData } from '@/util/system';
import deviceForPMApi from '@/api/pm/device';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';

export default defineComponent({
    components: {
        List,
        CameraEditDialog
    },
    props: {
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: 'camera'
        }
    },
    setup(props) {
        const updateData = ref(0);
        // 获取初始排序
        const initSortRes = getInitSortField('pmThirdPartyDevice');
        // 搜索条件
        const formData = reactive({
            Location: '',
            Build: 'all',
            Room: 'all',
            type: props.type,
            deviceType: props.deviceType,
            page: 1,
            row: 10,
            Sort: initSortRes.sort,
            SortField: initSortRes.sortField
        });

        overlayFormData(formData);

        // 获取build和room框数据
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        watch(() => formData.Build, (value) => {
            if (value === 'all') setRoomOption();
            else setRoomOption(value as string);
        }, {
            immediate: true
        });

        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = [
            {
                name: 'UnitName',
                label: WordList.DeviceDetailDetailUnitName
            },
            {
                name: 'RoomName',
                label: WordList.RDeviceSearchLabelRoomName
            },
            {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation,
                headerType: 'customize'
            }, {
                name: 'Name',
                label: WordList.TabelPersonUserInHtmlName
            }, {
                name: 'LinkDevice',
                label: WordList.LinkDevice
            }, {
                name: 'CreateTime',
                label: WordList.CreateTime,
                headerType: 'customize'
            }
        ];

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: number}>({
            row: [],
            detail: [],
            total: 0
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            deviceForPMApi.getThirdPartyDevice(formData, (res: {row: object[];detail: object[];total: number}) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateData.value += 1;
            });
        };
        getThirdPartyDeviceApi();

        // 编辑
        const isShowEditDialog = ref(false);
        const editData = ref({});

        const edit = (item: object) => {
            editData.value = item;
            isShowEditDialog.value = true;
        };

        const cameraClose = () => {
            isShowEditDialog.value = false;
        };

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            formData.Sort = sort;
            formData.SortField = sortField;
            getThirdPartyDeviceApi();
        }

        return {
            formData,
            buildOptions,
            aptOptions,
            column,
            getThirdPartyDeviceApi,
            tableData,
            updateData,
            isShowEditDialog,
            edit,
            editData,
            cameraClose,
            getSortImg,
            getSortRes
        };
    }
});
